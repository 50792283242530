import { TAnalyticsEventOrAnalyticsPackageDefault } from './domains';
import { EventEmitter } from './utils';

export type TAnalyticsLoggedEvent = {
    event: TAnalyticsEventOrAnalyticsPackageDefault;
} | {
    error: string;
} | {
    warning: string;
};

/** @internal */
export class AnalyticsLogger {
    static isEnabled: boolean = false;
    private static eventEmitter = new EventEmitter<TAnalyticsLoggedEvent>();

    static subscribe(handler: (event: TAnalyticsLoggedEvent) => void): void {
        AnalyticsLogger.eventEmitter.subscribe(handler);
    }

    static log(emittedEvent: TAnalyticsLoggedEvent) {
        AnalyticsLogger.eventEmitter.emit(emittedEvent);
    }

    static setConsoleLogger() {
        AnalyticsLogger.subscribe( loggedEvent => {
            if ('error' in loggedEvent) {
                console.error(loggedEvent.error);
                return;
            }
            if (!AnalyticsLogger.isEnabled) {
                return;
            }
            if ('event' in loggedEvent) {
                console.log(`Analytics event: ${JSON.stringify(loggedEvent.event, null, ' ')}`);
            } else {
                console.warn(loggedEvent.warning);
            }
        });
    }

    static setLoggerControlToWindow() {
        const Window = window as any;
        if (!Window['Analytics']) {
            Window['Analytics'] = {
                enableLogging: () => AnalyticsLogger.isEnabled = true,
                disableLogging: () => AnalyticsLogger.isEnabled = false,
                subscribe: (func: (event: TAnalyticsLoggedEvent) => void) => AnalyticsLogger.subscribe(func)
            };
        }
    }
}
