import { guid } from '../utils';

/** @internal */
export type TBrowser = {
  locale: string;
  user_agent: string;
  resolution: string;
  referrer: string;
  current_url: string;
  window_id: string;
  window_size: string;
};

// честный копипаст из https://stash.bank24.int/projects/T15/repos/t15-api/browse/src/networks/fingerprint-headers.ts
const NOT_AVAILABLE = 'not available';

function getLocale(): string {
  return navigator.languages ? navigator.languages.join(', ') : navigator.language || NOT_AVAILABLE;
}

function getResolution(): string {
  return window.screen ? `${window.screen.width}x${window.screen.height}` : NOT_AVAILABLE;
}

function getWindowSize(): string {
  const windowInnerWidth = document.documentElement.clientWidth;
  const windowInnerHeight = document.documentElement.clientHeight;

  return `${windowInnerWidth}x${windowInnerHeight}`;
}

function getReferrer(): string {
  let referrer = '';

  try {
      // tslint:disable-next-line: no-non-null-assertion
      referrer = window.top!.document.referrer;
  } catch (e) {
      if (window.parent) {
          try {
              referrer = window.parent.document.referrer;
          } catch (e) {
              referrer = '';
          }
      }
  }

  if (referrer === '') {
      referrer = document.referrer;
  }

  return referrer;
}

function getCurrentUrl(): string {
  return window.location.href;
}

function getUserAgent(): string {
  return navigator.userAgent;
}

const WINDOW_ID_STORAGE_KEY = 'tochka_analytics_window_id';

function getWindowId(): string {
    let id = sessionStorage.getItem(WINDOW_ID_STORAGE_KEY);
    if (!id) {
        id = guid().substring(0, 8);
        sessionStorage.setItem(WINDOW_ID_STORAGE_KEY, id);
    }
    return id;
}

/** @internal */
export function getBrowser(): TBrowser {
  return {
    locale: getLocale(),
    user_agent: getUserAgent(),
    resolution: getResolution(),
    referrer: getReferrer(),
    current_url: getCurrentUrl(),
    window_id: getWindowId(),
    window_size: getWindowSize()
  };
}
