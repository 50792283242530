import { AnalyticsPackageDefault } from './analytics-package-default';

export type TAnalyticsEvent = {
  category: string;
  action: string;
  params?: object;
};

export type TAnalyticsEventOrAnalyticsPackageDefault = TAnalyticsEvent | AnalyticsPackageDefault;

/** @internal */
export function isTAnalyticsEvent(event: TAnalyticsEventOrAnalyticsPackageDefault): event is TAnalyticsEvent {
  return 'params' in event && event.params !== null && typeof event.params === 'object';
}

/** @internal */
export type TEvent = TAnalyticsEvent;

/** @internal */
export function getEvent(event: TAnalyticsEventOrAnalyticsPackageDefault): TEvent {
  if (isTAnalyticsEvent(event)) {
    return event;
  }

  // goal, name, userId достаем, чтобы они не попали в ...rest и не отправлялись на бекенд
  const { category, action, goal, name, userId, label, ...rest } = event;

  const params: object = rest;
  if (label !== undefined) {
    // @ts-ignore
    params.label = label;
  }

  return {
    // tslint:disable-next-line: no-non-null-assertion
    category: category!,
    // tslint:disable-next-line: no-non-null-assertion
    action: action!,
    params
  };
}
