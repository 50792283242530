import { AnalyticsLogger } from './analytics.logger';
import { Analytics } from './analytics.service';
import { AnalyticsPackageDefault, TAnalyticsEventOrAnalyticsPackageDefault } from './domains';
import { isFunction } from './utils';

type AnalyticsHandler = (...args: Array<unknown>) => TAnalyticsEventOrAnalyticsPackageDefault | void;

interface PatchParam {
    key: string;
    worker: Function;
    defaults?: AnalyticsPackageDefault;
}

export function AnalyticsWorker(defaults?: AnalyticsPackageDefault): ClassDecorator {
    return function (worker: Function): void {
        try {
            Object.getOwnPropertyNames(worker).forEach(key => {
                patchHandler({ worker, key, defaults });
            });
        } catch (error) {
            const errorText = `AnalyticsWorker internal error (worker ${ worker.name }) ${ error }`;
            AnalyticsLogger.log({ error: errorText });
        }
    };
}

/**
* @deprecated используйте Analytics.sendEvent
*/
export function AnalyticsEvent(handler: AnalyticsHandler): MethodDecorator {
    return (target: object, key: string | symbol, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> => {
        try {
            const origin = descriptor.value;
            descriptor.value = function (...args: Array<any>): any {
                const result = origin.apply(this, args);
                handler(void 0);
                return result;
            };
        } catch (error) {
            const errorText = `AnalyticsEvent internal error (class ${ target.constructor.name }) ${ error }`;
            AnalyticsLogger.log({ error: errorText });
        }
        return descriptor;
    };
}

/** @internal */
function patchHandler(params: PatchParam): void {
    const { worker, key, defaults } = params;
    const descriptor = Object.getOwnPropertyDescriptor(worker, key);
    if (descriptor && isFunction(descriptor.value)) {
        const eventHandler: AnalyticsHandler = descriptor.value;
        descriptor.value = function (...args: Array<unknown>): TAnalyticsEventOrAnalyticsPackageDefault | void {
            try {
                let eventPackage = eventHandler.apply(this, args);
                if (eventPackage) {
                    eventPackage = { ...defaults, ...eventPackage };
                    Analytics.sendEvent(eventPackage);
                }
                return eventPackage;
            } catch (error) {
                const errorText = `Analytics handler error (worker ${ worker.name }, method ${ key }) ${ error }.`;
                AnalyticsLogger.log({ error: errorText });
            }
        };
        Object.defineProperty(worker, key, descriptor);
    }
}
