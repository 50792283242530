/** @internal */
export type TApplication = {
  platform: string;
};

/** @internal */
export function getApplication(): TApplication {
  return {
    platform: 'web'
  };
}
