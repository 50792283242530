/** @internal */
export type TSequenceNumber = string;

/** @internal */
export class SequenceNumber {
  private static counter: number = 0;

  static getSequenceNumber(): TSequenceNumber {
    return String(SequenceNumber.counter++);
  }
}
