import { getApplication, TApplication } from './application';
import { ClientUid, TClientUid } from './client-uid';
import { ContextParams, TContextParams } from './context-params';
import { getBrowser, TBrowser } from './browser';
import { getEvent, TEvent, TAnalyticsEventOrAnalyticsPackageDefault } from './event';
import { SequenceNumber, TSequenceNumber } from './sequence-number';
import { getTimeStamp, TTimeStamp } from './timestamp';

type TIdSite = string;

type TAnalyticsData = {
  idsite: TIdSite;
  timestamp: TTimeStamp;
  sequence_number: TSequenceNumber;
  client_uid: TClientUid;
  context_params: TContextParams;
  event: TEvent;
  browser: TBrowser;
  application: TApplication;
};

/** @internal */
export function buildRequest(idsite: TIdSite, event: TAnalyticsEventOrAnalyticsPackageDefault): TAnalyticsData {
  return {
    idsite,
    timestamp: getTimeStamp(),
    sequence_number: SequenceNumber.getSequenceNumber(),
    client_uid: ClientUid.getClientUid(),
    context_params: ContextParams.getParams(),
    event: getEvent(event),
    browser: getBrowser(),
    application: getApplication()
  };
}


/** @internal */
export function sendRequest(url: string, request: TAnalyticsData): void {
  // Раньше тут был Blob с типом json, но оно падало в старых браузерах https://crbug.com/724929
  // но теперь надо учитывать что мы посылаем в no-cors режиме
  const data = JSON.stringify(request);
  let isSuccess = false;
  try {
    isSuccess = navigator.sendBeacon(url, data);
  } catch (e) {}

  // Если по какой то причине не отправили beacon, то попробуем fetch
  if (!isSuccess) {
    void fetch(url, { 'body': data, 'method': 'POST' });
  }
}
