import { TAnalyticsLoggedEvent } from './analytics.logger';

/**
 * @deprecated дождаться когда все переедут на новую аналитику, а потом удалить
 * сейчас будет вести себя как и прежде
 */
export enum VariableType {
    /**
     * Переменная посылается только с одним событием
     */
    ACTION,
    /**
     * Переменная посылается с текущим и всеми последующими событиями
     */
    SESSION
}

export type TAnalyticsConfig = {
    /**
     * Идентификатор сайта/платформы/сервиса. Присваивается индивидуально каждому сайту/платформе/сервису.
     */
    idsite: string;

    /**
     * Урл, по которому будут посылаться события
     */
    url: string;

    /**
     * Домен для хранения куки client_uid, если нужно шарить uid между разными поддоменами,
     * то нужно указать более общий домен
     */
    clientUidCookieDomain: string;

    /**
     * Список кастомных переменных, которые можно прокинуть вместе с событием
     * @default []
     * @deprecated дождаться когда все переедут на новую аналитику, а потом удалить
     * сейчас будет вести себя как и прежде
     */
    variables?: Array<{
        name: string;
        id: number;
        type: VariableType;
    }>;

    /**
     * Логирование событий в консоль браузера
     * @default false
     * */
    logging?: boolean;

    /**
     * В продакшн моде логи и ошибки не будут сыпаться в консоль, а средства дебага выключены
     * @default true
     */
    production?: boolean;

    /**
     * Обработчик для дополнительного логирования событий
     */
    onEvent?: (event: TAnalyticsLoggedEvent) => void;
};
