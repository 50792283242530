import { isFunction } from './is-function';

interface Subscription {
    unsubscribe(): void;
}

/** @internal */
export class EventEmitter<TEvent> {
    private handlers: Array<(event: TEvent) => void> = [];
    subscribe(handler: (event: TEvent) => void): Subscription {
        if (!isFunction(handler)) {
            throw new Error('The subscriber handler must be a function');
        }
        this.handlers.push(handler);
        return {
            unsubscribe: (): void => {
                const index = this.handlers.indexOf(handler);
                if (index !== -1) {
                    this.handlers.splice(index, 1);
                }
            }
        };
    }

    emit(emittedEvent: TEvent) {
        const handlers = this.handlers.slice();
        handlers.forEach(handler => {
            try {
                handler(emittedEvent);
            } catch (e) {}
        });
    }
}
