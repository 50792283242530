import { Cookies, guid, GUID_LENGTH } from '../utils';

/** @internal */
export type TClientUid = string;

const CLIENT_UID_STORAGE_KEY = 'tochka_analytics_client_uid';

/**
 * @internal
 * UID клиента, стараемся сохранять его максимально стабильным, храним в куках и стораджах и восстанавливаем в случае чего
 */
export class ClientUid {
  private static clientUid: TClientUid = '';
  private static cookieDomain: string = location.hostname;

  static getClientUid(): TClientUid {
    return ClientUid.clientUid;
  }

  static init(cookieDomain: string): void {
    ClientUid.setCookieDomain(cookieDomain);
    ClientUid.restoreClientUID();
    ClientUid.setBeforeUnloadListener();
  }

  private static setCookieDomain(clientUidCookieDomain: string): void {
    ClientUid.cookieDomain = clientUidCookieDomain;
  }

  private static saveToAllStorages(): void {
    const value = ClientUid.clientUid;
    Cookies.set(CLIENT_UID_STORAGE_KEY, value, ClientUid.cookieDomain);
    sessionStorage.setItem(CLIENT_UID_STORAGE_KEY, value);
    localStorage.setItem(CLIENT_UID_STORAGE_KEY, value);
  }

  private static setBeforeUnloadListener(): void {
    window.addEventListener('beforeunload', ClientUid.saveToAllStorages);
  }

  private static restoreClientUID(): void {
    const allValues = [
      ClientUid.clientUid,
      Cookies.get(CLIENT_UID_STORAGE_KEY),
      sessionStorage.getItem(CLIENT_UID_STORAGE_KEY),
      localStorage.getItem(CLIENT_UID_STORAGE_KEY)
    ];

    // Ищем валидный uid, его могли удалить или переписать
    ClientUid.clientUid = allValues.find(value => typeof value === 'string' && value.length === GUID_LENGTH) ?? guid();

    ClientUid.saveToAllStorages();
  }
}
