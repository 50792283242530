// честный копипаст с https://stash.bank24.int/projects/T15/repos/t15-api/browse/src/utils/browser/cookies.ts

/**
 * Сервис для работы с куками
 */
 export class Cookies {
  /**
   * Возвращает значение куки по ее имени, или null, если ее нет
   */
  static get(name: string): string | null {
    return Cookies.getAll()[name] ?? null;
  }

  /**
   * Возвращает все куки
   */
  static getAll(): Record<string, string> {
    const rawCookies = document.cookie ? document.cookie.split(';') : [];
    const cookies: Record<string, string> = {};

    for (const cookie of rawCookies) {
      try {
        const [name, value] = cookie
          .trim()
          .split('=')
          .map((part: string): string => decodeURIComponent(part));

        if (name !== undefined && value !== undefined) {
          cookies[name] = value;
        }
      } catch (e) {}
    }

    return cookies;
  }

  /**
   * Устанавливает куку
   */
  static set(name: string, value: string, domain: string): void {
    // tslint:disable-next-line: max-line-length
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; domain=${encodeURIComponent(domain)}; Path=/; sameSite=strict; secure; max-age=2147483647`;
  }

  /**
   * Удаляет куку
   */
  static delete(name: string): void {
    document.cookie = `${encodeURIComponent(name)}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  }
}
