export type TContextParamValue = string | boolean;

export type TContextParams = Record<string, TContextParamValue>;

/** @internal */
export class ContextParams {
  private static params: TContextParams = {};

  static setParam(name: string, value: TContextParamValue): void {
    ContextParams.params[name] = value;
  }

  static getParams(): TContextParams {
    return ContextParams.params;
  }
}
