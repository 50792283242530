// честный копипаст с https://stash.bank24.int/projects/T15/repos/t15-api/browse/src/utils/guid.ts
export function guid(): string {
  function s4(): string {
    const hexSize = 16;
    const chunkSize = 0x10000;

    return Math.floor((1 + Math.random()) * chunkSize).toString(hexSize).substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export const GUID_LENGTH = 36;
