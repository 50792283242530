import { TAnalyticsConfig } from './analytics.config';
import { AnalyticsLogger } from './analytics.logger';
import { AnalyticsTransport } from './analytics.transport';
import { TAnalyticsEventOrAnalyticsPackageDefault, ContextParams, TContextParams, ClientUid } from './domains';

export class Analytics {
    /** @internal */
    private static config: TAnalyticsConfig | undefined;

    static init(customConfig: TAnalyticsConfig): void {
        try {
            Analytics.config = { production: true, logging: false, ...customConfig };
            Analytics.setLogging(Analytics.config);
            ClientUid.init(Analytics.config.clientUidCookieDomain);
            AnalyticsTransport.init(Analytics.config).catch(error => {
                AnalyticsLogger.log({ error });
            });
        } catch (error) {
            const errorText = 'Error analytics init failed. ' + error;
            AnalyticsLogger.log({ error: errorText });
        }
    }

    /** @internal */
    private static setLogging(config: TAnalyticsConfig): void {
        AnalyticsLogger.isEnabled = config.logging || false;
        if (config.onEvent) {
            AnalyticsLogger.subscribe(config.onEvent);
        }
        if (!config.production) {
            AnalyticsLogger.setConsoleLogger();
            AnalyticsLogger.setLoggerControlToWindow();
        }
    }

    static sendEvent(event: TAnalyticsEventOrAnalyticsPackageDefault): void {
        // Делаем отправку асинхронной, чтоб не ломать процесс исполнения кода, но при этом не глушить ошибки
        Promise.resolve().then(() => {
            AnalyticsTransport.sendEvent(event);
            AnalyticsLogger.log({ event });
        });
    }

    static setContextParams(params: TContextParams): void {
        Object.entries(params).forEach(([key, value]) => {
            ContextParams.setParam(key, value);
        });
    }
}
