import { TAnalyticsConfig, VariableType } from './analytics.config';
import {
    ContextParams,
    TAnalyticsEventOrAnalyticsPackageDefault,
    buildRequest,
    sendRequest,
    AnalyticsPackageDefault,
    isTAnalyticsEvent
} from './domains';
import { isUndefined } from './utils';

/**
* @deprecated дождаться когда все переедут на Analytics.setContextParam, а потом удалить, так как используется только там
*/
type TAnalyticsPackageAllParams = { [name: string]: string | number | boolean | void; };

/** @internal */
export class AnalyticsTransport {
    private static config: TAnalyticsConfig;

    /**
    * @deprecated дождаться когда все переедут на Analytics.setContextParam, а потом удалить setCustoms
    */
    private static setCustomsAndExcludeSessionVariables(event: TAnalyticsPackageAllParams): void {
        const { config } = AnalyticsTransport;
        const { variables = [] } = config;
        variables.forEach(variable => {
            if (variable.name in event) {
                const variableValue = event[variable.name];
                if (variableValue !== undefined && variable.type === VariableType.SESSION) {
                    // оставляем только строки и булеаны
                    const supportedValue = typeof variableValue === 'boolean' ? variableValue : String(variableValue);
                    ContextParams.setParam(variable.name, supportedValue);

                    // да, мутируем ивент, но все равно потом удалим этот метод
                    // это чтобы при const { category, action, goal, name, userId, ...rest } = event;
                    // в ...rest не дублировались SESSION_PARAMS
                    // ВАЖНО: setCustomsAndExcludeSessionVariables должен идти перед trackEvent,
                    // чтобы сначала SESSION_PARAMS просаживались, а потом уже очищались (перед отправкой события)
                    delete event[variable.name];

                    return;
                }
            }
        });
    }

    /**
    * @deprecated дождаться когда все переедут на новую аналитику, а потом удалить
    * надо просаживать userId через Analytics.setCustomParam
    * сейчас будет вести себя как и прежде
    */
    private static setUserId(event: AnalyticsPackageDefault) {
        if (!isUndefined(event.userId)) {
            ContextParams.setParam('user_id', event.userId);
        }
    }

    private static trackEvent(event: TAnalyticsEventOrAnalyticsPackageDefault) {
        if (event.category && event.action) {
            const { url, idsite } = AnalyticsTransport.config;
            const request = buildRequest(idsite, event);
            sendRequest(url, request);
        }
    }

    static init(config: TAnalyticsConfig): Promise<void> {
        if (AnalyticsTransport.config) {
            return Promise.reject('Error. Analytics already initialized');
        }
        AnalyticsTransport.config = config;

        return Promise.resolve();
    }

    static sendEvent(event: TAnalyticsEventOrAnalyticsPackageDefault) {
        if (!AnalyticsTransport.config) {
            return;
        }

        if (!isTAnalyticsEvent(event)) {
            AnalyticsTransport.setUserId(event);
            AnalyticsTransport.setCustomsAndExcludeSessionVariables(event);
        }
        AnalyticsTransport.trackEvent(event);
    }
}
