/** @internal */
export type TTimeStamp = string;

// честный копипаст с https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
function pad(num: number): string {
  return (num < 10 ? '0' : '') + num;
}

function toIsoString(date: Date): TTimeStamp {
  const timeZoneOffset = -date.getTimezoneOffset();
  const timeZoneSign = timeZoneOffset >= 0 ? '+' : '-';

  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      '.' + pad(date.getMilliseconds()) +
      timeZoneSign + pad(Math.floor(Math.abs(timeZoneOffset) / 60)) +
      // тут специально нету двоеточия, бекенд аналитики принимает timestamp в формате "2022-02-22T16:39:53.398+0500"
      pad(Math.abs(timeZoneOffset) % 60);
}

/** @internal */
export function getTimeStamp(): TTimeStamp {
  return toIsoString(new Date());
}
